import { useState } from "react";
import {
  convertTimestampToObject,
  getSimpleTimeInUserTimeZone,
} from "../../publicFunctions/publicHelpers/helpers";
import BasicUserInfo from "../BasicUserInfo";
import EditAThing from "../forum/EditAThing";
import {
  deleteComment,
  updateComment,
} from "../../publicFunctions/forumHandler";
import DeleteAThing from "../forum/DeleteAThing";
import ReplyToComment from "./ReplyToComment";

function Comment({
  publicUser,
  comment,
  uploadedStory,
  storyId,
  canEdit,
  thisIsMyStory,
  myUserId,
  forceRefresh,
  highlighted,
}) {
  const [realContent, setRealContent] = useState(comment.comment);
  const [editedContent, setEditedContent] = useState(comment.comment);
  const [editing, setEditing] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const returnReadSection =
    storyId && uploadedStory.battleId && storyId !== uploadedStory.storyId
      ? true
      : false;

  return (
    <div
      className={
        "comment-container" +
        (highlighted ? " purple-background--lightest" : "")
      }
    >
      <div className="leave-a-comment-section">
        {publicUser ? <BasicUserInfo publicUserInfo={publicUser} /> : null}
        <div
          className={
            "flex flex--direction-column" +
            (!returnReadSection ? " message-expand" : "")
          }
        >
          {canEdit || thisIsMyStory ? (
            <DeleteAThing
              description={"comment"}
              handleDelete={() => {
                deleteComment(
                  comment.commentId,
                  thisIsMyStory && !canEdit ? true : false,
                  uploadedStory.storyId
                ).then((success) => {
                  forceRefresh();
                });
              }}
            />
          ) : null}
          {comment.createdDate ? (
            <>
              <p className="description--smaller">
                {getSimpleTimeInUserTimeZone(
                  convertTimestampToObject(comment.createdDate)
                )}
              </p>
              <br />
            </>
          ) : null}
          {editing ? (
            <textarea
              className="feedback-text-area text-area--resize-vertical"
              value={editedContent}
              onChange={(event) => {
                setEditedContent(event.target.value);
                setChangesMade(true);
              }}
            />
          ) : (
            <p className="description profile-text">{realContent}</p>
          )}
          <br />
          <br />

          {editing ? (
            <div className="flex justify-content--center">
              <button
                onClick={() => {
                  updateComment(comment.commentId, editedContent)
                    .then(() => {
                      setRealContent(editedContent);
                    })
                    .finally(() => {
                      setEditing(false);
                      setChangesMade(false);
                    });
                }}
                className="btn btn--outline min-width--even-larger margin-top-bigger"
                disabled={!changesMade}
              >
                Update Comment
              </button>
            </div>
          ) : null}

          {/* EDIT */}
          {canEdit ? (
            <EditAThing
              toggleEditHandler={(shouldBeEditing) => {
                setEditing(shouldBeEditing);
              }}
              argEditing={editing}
              justifySelfEnd={true}
            />
          ) : null}
        </div>

        {returnReadSection ? (
          <p
            onClick={() => {
              window.open(
                "/story/debrief/" +
                  // uploadedStory.battleId +
                  // "/" +
                  storyId +
                  "?uploadedStory",
                "_blank"
              );
            }}
            className={
              "description underline cursor--pointer" +
              " align-self--end justify-self--end"
            }
          >
            Return Read
          </p>
        ) : null}
      </div>
      {((!thisIsMyStory || !canEdit) && thisIsMyStory) ||
      (comment.replyComment && comment.replyUserId) ? (
        <ReplyToComment
          commentId={comment.commentId}
          storyId={uploadedStory?.storyId ? uploadedStory.storyId : ""}
          argReplyComment={comment.replyComment ? comment.replyComment : ""}
          argReplyUserId={comment.replyUserId ? comment.replyUserId : ""}
          thisIsMyStory={thisIsMyStory}
          returnReadAvail={storyId ? true : false}
        />
      ) : null}
    </div>
  );
}

export default Comment;
