import {
  flowerOutline,
  flashOutline,
  sunnyOutline,
  leafOutline,
  snowOutline,
  heartOutline,
  starOutline,
  skullOutline,
} from "ionicons/icons";
import BattleSwatch from "../clientClasses/BattleSwatch";

import QUEEN_LANDSCAPE_AUTUMN from "../../img/home/queen_landscape_autumn.webp";
import QUEEN_LANDSCAPE_WINTER from "../../img/home/queen_landscape_winter.webp";
import QUEEN_LANDSCAPE_SPRING from "../../img/home/Spring_Landscape_.webp";
import QUEEN_LANDSCAPE_SUMMER from "../../img/home/Summer_Landscape_.webp";
import SENATOR_LANDSCAPE_HEART from "../../img/home/HeartLandscape.webp";
import SENATOR_LANDSCAPE_WONDER from "../../img/home/WonderLandscape.webp";
import SENATOR_LANDSCAPE_FEAR from "../../img/home/FearLandscape.webp";
import HALF_QUEEN_LANDSCAPE_WINTER from "../../img/halfQueens/WinterLandscape.webp";
import HALF_QUEEN_LANDSCAPE_SPRING from "../../img/halfQueens/Spring_Half_Landscape.webp";
import HALF_QUEEN_LANDSCAPE_SUMMER from "../../img/halfQueens/Summer_Half_Landscape.webp";
import HALF_QUEEN_LANDSCAPE_AUTUMN from "../../img/halfQueens/AutumnLandscape.webp";
import HALF_SENATOR_LANDSCAPE_HEART from "../../img/halfQueens/HalfLandscape_Heart.webp";
import HALF_SENATOR_LANDSCAPE_WONDER from "../../img/halfQueens/HalfLandscape_Wonder.webp";
import HALF_SENATOR_LANDSCAPE_FEAR from "../../img/halfQueens/HalfLandscape_Fear.webp";
import QUARTER_QUEEN_LANDSCAPE_WINTER from "../../img/halfQueens/quarters/Winter_quarter.webp";
import QUARTER_QUEEN_LANDSCAPE_SPRING from "../../img/halfQueens/quarters/Spring_quarter.webp";
import QUARTER_QUEEN_LANDSCAPE_SUMMER from "../../img/halfQueens/quarters/Summer_quarter.webp";
import QUARTER_QUEEN_LANDSCAPE_AUTUMN from "../../img/halfQueens/quarters/Autumn_quarter.webp";
import QUARTER_SENATOR_LANDSCAPE_HEART from "../../img/halfQueens/quarters/QuarterLandscape_Heart.webp";
import QUARTER_SENATOR_LANDSCAPE_WONDER from "../../img/halfQueens/quarters/QuarterLandscape_Wonder.webp";
import QUARTER_SENATOR_LANDSCAPE_FEAR from "../../img/halfQueens/quarters/QuarterLandscape_Fear.webp";
import KING_COINS from "../../img/home/King_Coin.webp";
import KING_CUPS from "../../img/home/King_Cups.webp";
import KING_SWORDS from "../../img/home/King_Sword.webp";
import KING_WANDS from "../../img/home/King_Wands.webp";
import PAGE_COINS from "../../img/home/Page_Coin.webp";
import PAGE_CUPS from "../../img/home/Page_Cups.webp";
import PAGE_SWORDS from "../../img/home/Page_Sword.webp";
import PAGE_WANDS from "../../img/home/Page_Wands.webp";
import QUEEN_COINS from "../../img/home/Queen_Coin.webp";
import QUEEN_CUPS from "../../img/home/Queen_Cups.webp";
import QUEEN_SWORDS from "../../img/home/Queen_Sword.webp";
import QUEEN_WANDS from "../../img/home/Queen_Wands.webp";

export const WINTER = "WINTER";
export const SPRING = "SPRING";
export const SUMMER = "SUMMER";
export const AUTUMN = "AUTUMN";
export const HEART = "HEART";
export const WONDER = "WONDER";
export const FEAR = "FEAR";

// colors
const AUTUMN_COLOR = "#c37959";
const AUTUMN_COLOR_DARKER = "#9c6147";
const AUTUMN_COLOR_DARKEST = "#754935";

const WINTER_COLOR = "#80959d";
const WINTER_COLOR_DARKER = "#66777e";
const WINTER_COLOR_DARKEST = "#4d595e";

const SPRING_COLOR = "#d03f37";
const SPRING_COLOR_DARKER = "#a6322c";
const SPRING_COLOR_DARKEST = "#7d2621";

const SUMMER_COLOR = "#8a975f";
const SUMMER_COLOR_DARKER = "#6e794c";
const SUMMER_COLOR_DARKEST = "#535b39";

const HEART_COLOR = "#be8128";
const HEART_COLOR_DARKER = "#986720";
const HEART_COLOR_DARKEST = "#724d18";

const WONDER_COLOR = "#b05344";
const WONDER_COLOR_DARKER = "#8d4236";
const WONDER_COLOR_DARKEST = "#6a3229";

const FEAR_COLOR = "#a7702d";
const FEAR_COLOR_DARKER = "#865a24";
const FEAR_COLOR_DARKEST = "#64431b";

function getColorKeyByDate(date) {
  if (!date) {
    date = new Date();
  }
  const month = date.getMonth();
  if (month === 11 || month === 0) {
    return HEART;
  } else if (month === 1) {
    return WINTER;
  } else if (month === 2 || month === 3) {
    return WONDER;
  } else if (month === 4) {
    return SPRING;
  } else if (month === 5 || month === 6) {
    return SUMMER;
  } else if (month === 7 || month === 8) {
    return FEAR;
  } else {
    return AUTUMN;
  }
}

function getColorKeyByBattleId(battleId) {
  if (!battleId) {
    return AUTUMN;
  }
  if (battleId.includes("feb")) {
    return WINTER;
  } else if (battleId.includes("may")) {
    return SPRING;
  } else if (battleId.includes("aug")) {
    return SUMMER;
  } else if (battleId.includes("heart")) {
    return HEART;
  } else if (battleId.includes("wonder")) {
    return WONDER;
  } else if (battleId.includes("fear")) {
    return FEAR;
  } else {
    // nov and fear
    return AUTUMN;
  }
}

function getPageForColorKey(colorKey) {
  if (colorKey === AUTUMN || colorKey === FEAR) {
    return PAGE_WANDS;
  } else if (colorKey === WINTER || colorKey === HEART) {
    return PAGE_SWORDS;
  } else if (colorKey === SPRING || colorKey === WONDER) {
    return PAGE_COINS;
  } else if (colorKey === SUMMER) {
    return PAGE_CUPS;
  } else {
    // default
    return PAGE_WANDS;
  }
}

function getQueenForColorKey(colorKey) {
  if (colorKey === AUTUMN || colorKey === FEAR) {
    return QUEEN_WANDS;
  } else if (colorKey === WINTER || colorKey === HEART) {
    return QUEEN_SWORDS;
  } else if (colorKey === SPRING || colorKey === WONDER) {
    return QUEEN_COINS;
  } else if (colorKey === SUMMER) {
    return QUEEN_CUPS;
  } else {
    // default
    return QUEEN_WANDS;
  }
}

function getKingForColorKey(colorKey) {
  if (colorKey === AUTUMN || colorKey === FEAR) {
    return KING_WANDS;
  } else if (colorKey === WINTER || colorKey === HEART) {
    return KING_SWORDS;
  } else if (colorKey === SPRING || colorKey === WONDER) {
    return KING_COINS;
  } else if (colorKey === SUMMER) {
    return KING_CUPS;
  } else {
    // default
    return KING_WANDS;
  }
}

function getHalfLandscapeForColorKey(colorKey) {
  if (colorKey === AUTUMN) {
    return HALF_QUEEN_LANDSCAPE_AUTUMN;
  } else if (colorKey === WINTER) {
    return HALF_QUEEN_LANDSCAPE_WINTER;
  } else if (colorKey === SPRING) {
    return HALF_QUEEN_LANDSCAPE_SPRING;
  } else if (colorKey === SUMMER) {
    return HALF_QUEEN_LANDSCAPE_SUMMER;
  } else if (colorKey === HEART) {
    return HALF_SENATOR_LANDSCAPE_HEART;
  } else if (colorKey === WONDER) {
    return HALF_SENATOR_LANDSCAPE_WONDER;
  } else if (colorKey === FEAR) {
    return HALF_SENATOR_LANDSCAPE_FEAR;
  } else {
    // default
    return HALF_QUEEN_LANDSCAPE_AUTUMN;
  }
}

function getQuarterLandscapeForColorKey(colorKey) {
  if (colorKey === AUTUMN) {
    return QUARTER_QUEEN_LANDSCAPE_AUTUMN;
  } else if (colorKey === WINTER) {
    return QUARTER_QUEEN_LANDSCAPE_WINTER;
  } else if (colorKey === SPRING) {
    return QUARTER_QUEEN_LANDSCAPE_SPRING;
  } else if (colorKey === SUMMER) {
    return QUARTER_QUEEN_LANDSCAPE_SUMMER;
  } else if (colorKey === HEART) {
    return QUARTER_SENATOR_LANDSCAPE_HEART;
  } else if (colorKey === WONDER) {
    return QUARTER_SENATOR_LANDSCAPE_WONDER;
  } else if (colorKey === FEAR) {
    return QUARTER_SENATOR_LANDSCAPE_FEAR;
  } else {
    // default
    return QUARTER_QUEEN_LANDSCAPE_AUTUMN;
  }
}

function getLandscape(colorKey) {
  if (colorKey === AUTUMN) {
    return QUEEN_LANDSCAPE_AUTUMN;
  } else if (colorKey === WINTER) {
    return QUEEN_LANDSCAPE_WINTER;
  } else if (colorKey === SPRING) {
    return QUEEN_LANDSCAPE_SPRING;
  } else if (colorKey === SUMMER) {
    return QUEEN_LANDSCAPE_SUMMER;
  } else if (colorKey === HEART) {
    return SENATOR_LANDSCAPE_HEART;
  } else if (colorKey === WONDER) {
    return SENATOR_LANDSCAPE_WONDER;
  } else if (colorKey === FEAR) {
    return QUEEN_LANDSCAPE_AUTUMN; // TODO: change this to fear AFTER OCTOBER
  } else {
    // default
    return QUEEN_LANDSCAPE_AUTUMN;
  }
}

function getLandscapeFontColor(colorKey) {
  if (colorKey === AUTUMN || colorKey === FEAR) {
    // note, remove this and uncomment below AFTER OCTOBER
    return AUTUMN_COLOR;
  } else if (colorKey === WINTER) {
    return WINTER_COLOR;
  } else if (colorKey === SPRING) {
    return SPRING_COLOR;
  } else if (colorKey === SUMMER) {
    return SUMMER_COLOR;
  } else if (colorKey === HEART) {
    return HEART_COLOR;
  } else if (colorKey === WONDER) {
    return WONDER_COLOR;
  }
  // uncomment AFTER OCTOBER
  // else if (colorKey === FEAR) {
  //   return FEAR_COLOR;
  // }
  else {
    // default
    return AUTUMN_COLOR;
  }
}

function getDarkerFontColor(color) {
  if (color === AUTUMN_COLOR || color === FEAR_COLOR) {
    // note, remove this and uncomment below AFTER OCTOBER
    return AUTUMN_COLOR_DARKER;
  } else if (color === WINTER_COLOR) {
    return WINTER_COLOR_DARKER;
  } else if (color === SPRING_COLOR) {
    return SPRING_COLOR_DARKER;
  } else if (color === SUMMER_COLOR) {
    return SUMMER_COLOR_DARKER;
  } else if (color === HEART_COLOR) {
    return HEART_COLOR_DARKER;
  } else if (color === WONDER_COLOR) {
    return WONDER_COLOR_DARKER;
  }
  // uncomment AFTER OCTOBER
  // else if (color === FEAR) {
  //   return FEAR_COLOR_DARKER;
  // }
  else {
    // default
    return AUTUMN_COLOR_DARKER;
  }
}

function getDarkestFontColor(color) {
  if (color === AUTUMN_COLOR || color === FEAR_COLOR) {
    // note, remove this and uncomment below AFTER OCTOBER
    return AUTUMN_COLOR_DARKEST;
  } else if (color === WINTER_COLOR) {
    return WINTER_COLOR_DARKEST;
  } else if (color === SPRING_COLOR) {
    return SPRING_COLOR_DARKEST;
  } else if (color === SUMMER_COLOR) {
    return SUMMER_COLOR_DARKEST;
  } else if (color === HEART_COLOR) {
    return HEART_COLOR_DARKEST;
  } else if (color === WONDER_COLOR) {
    return WONDER_COLOR_DARKEST;
  }
  // uncomment AFTER OCTOBER
  // else if (color === FEAR) {
  //   return FEAR_COLOR_DARKEST;
  // }
  else {
    // default
    return AUTUMN_COLOR_DARKEST;
  }
}

function getBattleSwatchByColorKey(colorKey) {
  const color = getLandscapeFontColor(colorKey);
  return new BattleSwatch(
    colorKey,
    getLandscape(colorKey),
    getHalfLandscapeForColorKey(colorKey),
    getQuarterLandscapeForColorKey(colorKey),
    getPageForColorKey(colorKey),
    getQueenForColorKey(colorKey),
    getKingForColorKey(colorKey),
    color,
    getDarkerFontColor(color),
    getDarkestFontColor(color)
  );
}

// export functions

export function getBattleSwatchByDate(date) {
  return getBattleSwatchByColorKey(getColorKeyByDate(date));
}

export function getBattleSwatchByBattleId(battleId) {
  return getBattleSwatchByColorKey(getColorKeyByBattleId(battleId));
}

export function getIconByBattleId(battleId) {
  if (battleId.includes("may")) return flowerOutline;
  else if (battleId.includes("aug")) return sunnyOutline;
  else if (battleId.includes("nov")) return leafOutline;
  else if (battleId.includes("feb")) return snowOutline;
  else if (battleId.includes("heart")) return heartOutline;
  else if (battleId.includes("wonder")) return starOutline;
  else if (battleId.includes("fear")) return skullOutline;
  else return flashOutline;
}
