import { IonIcon } from "@ionic/react";
import {
  readerOutline,
  flashOutline,
  arrowForwardCircleOutline,
  chatboxEllipsesOutline,
  medalOutline,
  arrowRedoOutline,
} from "ionicons/icons";
import { getSimpleTimeInUserTimeZone } from "../../publicFunctions/publicHelpers/helpers";

function NotificationItem({ notificationObj, isNew }) {
  return (
    <a
      href={constructNotifictionURL(notificationObj)}
      role="button"
      className={
        "notification-item " +
        "align-items--center " +
        "flex--gap-smaller padding--small " +
        "text-dec--none text-color--inherit" +
        (isNew ? " notification-item--new" : "")
      }
    >
      <IonIcon
        className="social-icon"
        icon={getIconForType(notificationObj.type)}
      />
      <p className="description--small">
        {constructNotificationText(notificationObj)}
        <br />
        <span className="italic description--smaller">
          {getSimpleTimeInUserTimeZone(notificationObj.date)}
        </span>
      </p>
      <div style={{ justifySelf: "flex-end" }}>
        <IonIcon className="social-icon" icon={arrowForwardCircleOutline} />
      </div>
    </a>
  );
}

function constructNotifictionURL(notificationObj) {
  if (
    notificationObj?.type === "storyComment" ||
    notificationObj?.type === "storyCommentReply"
  ) {
    const isDebrief = notificationObj.isDebrief;
    let postURL;
    if (isDebrief) {
      postURL = "/story/debrief/";
    } else {
      postURL = "/story/uploads/";
    }
    return (
      postURL +
      notificationObj.documentId +
      "?toComments" +
      (notificationObj.type === "storyCommentReply" && notificationObj.commentId
        ? "&commentId=" + notificationObj.commentId
        : "")
    );
  } else if (notificationObj?.type === "forumMessage") {
    return (
      "/forum?page=topic&topicId=" +
      notificationObj.documentId +
      "&searchTime=" +
      notificationObj.date.getTime().toString(10) +
      "&searchMessage=" +
      notificationObj.messageId
    );
  } else if (notificationObj?.type === "cardsEarned") {
    return "/tavern?battleId=tavern&page=collection";
  } else {
    return "";
  }
}

function constructNotificationText(notificationObj) {
  const DEFAULT = "Hey! Consider yourself notified ;)";
  if (notificationObj?.type === "storyComment") {
    if (!notificationObj.detailText) {
      return DEFAULT;
    } else {
      const name = notificationObj.name ? notificationObj.name : "Someone";
      return name + " commented on your story, " + notificationObj.detailText;
    }
  } else if (notificationObj?.type === "storyCommentReply") {
    if (!notificationObj.detailText) {
      return DEFAULT;
    } else {
      const name = notificationObj.name ? notificationObj.name : "Someone";
      return (
        name +
        " replied to a comment you left on their story, " +
        notificationObj.detailText
      );
    }
  } else if (notificationObj?.type === "forumMessage") {
    if (!notificationObj.detailText) {
      return DEFAULT;
    } else {
      const name = notificationObj.name ? notificationObj.name : "Someone";
      if (notificationObj.isTaggedUser) {
        return (
          name +
          " tagged you in a post on the forum topic " +
          notificationObj.detailText
        );
      } else {
        return (
          name +
          " posted on the topic you're subscribed to: " +
          notificationObj.detailText
        );
      }
    }
  } else if (notificationObj?.type === "cardsEarned") {
    const defaultPostScript =
      " Click this notification to take you to your card collection in the Tavern.";
    if (notificationObj.earnedFromBattle) {
      return "Woohoo! You earned cards from Battle!" + defaultPostScript;
    } else if (!notificationObj.raidName) {
      return "Woohoo! You earned cards from a Raid!" + defaultPostScript;
    } else {
      return (
        "Woohoo! You earned cards from the Raid, " +
        notificationObj.raidName +
        "!" +
        defaultPostScript
      );
    }
  } else {
    return DEFAULT;
  }
}

function getIconForType(type) {
  const DEFAULT = flashOutline;
  if (!type) {
    return DEFAULT;
  }

  if (type === "storyComment") {
    return readerOutline;
  } else if (type === "storyCommentReply") {
    return arrowRedoOutline;
  } else if (type === "forumMessage") {
    return chatboxEllipsesOutline;
  } else if (type === "cardsEarned") {
    return medalOutline;
  } else {
    return DEFAULT;
  }
}

export default NotificationItem;
